
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class stelline extends mixins(WithModel) {

    @Prop() readonly: boolean;

    declare localValue: number;

    emitValue(val){
        if(this.readonly)
            return;

        this.emitModel(val);
    }

}

