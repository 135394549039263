import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/category/:category/purchase',
            name: 'purchasecategorypackages',
            component: () => import("./views/categoryPurchasePackages.vue")
        }
    ];
};