import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'login',
            component: () => import("./views/login.vue")
        },
        {
            path: '/confirm_email/:code/:loginIdentifier',
            name: 'confirm_email',
            component: () => import("./views/confirmEmail.vue")
        },
        {
            path: '/register',
            name: 'register',
            redirect: '/',
        },
        {
            path: '/bookingpromo',
            name: 'bookingpromo',
            redirect: '/',
        },
        {
            path: '/registrati',
            name: 'registrati',
            component: () => import("./views/registrati.vue")
        },
        {
            path: '/reset_password',
            name: 'reset_password',
            component: () => import("./views/resetPassword.vue")
        },
        {
            path: '/change_password',
            name: 'change_password',
            component: () => import("./views/changePassword.vue")
        }
    ];
};