import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import begin from '@/views/begin';
import home from '@/views/home';
import appuntamenti from '@/views/appuntamenti';
import profilo from '@/views/profilo';
import notifiche from '@/views/notifiche';
import atupertu from '@/views/atupertu';
import academy from '@/views/academy';
import webinar from '@/views/webinar';
import faq from '@/views/faq';
import category from '@/views/category';
import imieipacchetti from '@/views/imieipacchetti';
import restrictedCategory from '@/views/restrictedCategory';

const routes: Array<RouteRecordRaw> = [
    ...begin.routes(),
    {
        path: '/',
        component: () => import("../views/layout/layout.vue"),
        children: [
            ...home.routes(),
            ...appuntamenti.routes(),
            ...profilo.routes(),
            ...notifiche.routes(),
            ...imieipacchetti.routes(),
            ...faq.routes(),
            ...category.routes(),
            ...restrictedCategory.routes()
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
    }
})


router.beforeEach((to, from, next) => {
    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
})

router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
})

export default router
