
import * as OM from '@/Model';
import * as Const from '@/const';
import * as VM from '@/viewModel';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class datoAggiuntivo extends mixins(WithModel) {

    @Prop() config: OM.AdditionalFieldConfig;

    created(){
        if(!this.localValue){
            this.localValue = [];
        }
        if(!this.config.isRispostaMultipla && this.localValue[0] == null){
            this.localValue[0] = "";
        }
    }

}
