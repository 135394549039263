
import { Prop, Watch } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import store from '@/store';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class LocalizedText extends Vue {

    @Prop({ default: "span"}) type: string;
    @Prop({ default: "" }) localizedKey: string;
    @Prop({ default: "" }) text: string;
    @Prop({ default: "" }) prevText: string;
    @Prop({ default: "" }) afterText: string;
    @Prop({ default: "" }) object: any;

    // @Prop() spanClass: string;

    @Prop({
        default: 0
    }) numberAsterisk: number;

    localizedValues: OM.LocalizedEntity[] = [];
    translatedText: string = "";

    @Watch('localizedKey')
    @Watch('prevText')
    @Watch('afterText')
    @Watch('object')
    onLocalizedKey(next, prev){
        this.setLocalizedText();
    }

    @Watch('prevText')
    @Watch('afterText')
    onAdditionalTextChange() {
        this.setLocalizedText();
    }

    unwatcher: () => void = null;
    mounted() {
        this.setLocalizedText();
        this.unwatcher = store.watch( 
            state => {
                return state.company.companyCode || "it-IT"
            }, 
            () => { 
                this.setLocalizedText(); 
            }
        )
    }

    beforeUnmount(){
        this.unwatcher();
    }

    setLocalizedText() {
        this.translatedText = LocalizationServices.GetLocalizedValue(this.localizedKey, this.text, this.afterText, this.prevText, this.object);
    }
}
