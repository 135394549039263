import * as OM from '@/Model';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    getUserIdentifier() {
        return this.readFromLocalStorage<string>('userIdentifier');
    }
    setUserIdentifier(value: string){
        window.localStorage.setItem('userIdentifier', JSON.stringify(value));
    }

    getCompanyData() {
        return this.readFromLocalStorage<OM.Company>('companyData');
    }
    setCompanyData(value: OM.Company){
        window.localStorage.setItem('companyData', JSON.stringify(value));
    }
    
    getMakePrenotazioneVm() {
        return this.readFromLocalStorage<OM.MakePrenotazioneVm>('MakePrenotazioneVm');
    }
    setMakePrenotazioneVm(vm: OM.MakePrenotazioneVm) {
        window.localStorage.setItem('MakePrenotazioneVm', JSON.stringify(vm));
    }

    getSupportoMakePrenotazioneVm() {
        return this.readFromLocalStorage<OM.SupportoMakePrenotazioneVm>('SupportoMakePrenotazioneVm');
    }
    setSupportoMakePrenotazioneVm(vm: OM.SupportoMakePrenotazioneVm) {
        window.localStorage.setItem('SupportoMakePrenotazioneVm', JSON.stringify(vm));
    }
    
}

export let StorageServices = new _StorageServices();