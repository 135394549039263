
import { Swiper, SwiperOptions } from 'swiper';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class SwiperSlides extends Vue {
    
    @Prop({
        type: Number,
        default: 0
    }) slideIndex: number;

    @Prop({
        default: true
    }) showPagination: boolean;
    
    @Prop({
        default: true
    }) autoHeight: boolean;

    @Prop({
        default: false
    }) noSwiping: boolean;

    swiper: any;
    mounted(){
        let swiperParams: SwiperOptions = {
            pagination: {
                el: '.swiper-pagination',
            },
            autoHeight: this.autoHeight,
            direction: 'horizontal',
            initialSlide: this.slideIndex,
            speed: 100,
            on: {
                slideChange: this.onSlideChange,
            },
            noSwiping: !this.noSwiping,
            noSwipingClass: 'swiper-slide',
            swipeHandler: this.noSwiping ? 'pastello' : null
        };
        this.swiper = new Swiper(<HTMLElement>this.$refs.swiperContainer, swiperParams);
    }
    @Watch('slideIndex')
    onSlideIndexChange(next, prev){
        this.swiper.slideTo(next);
    }

    onSlideChange(){
        if(this.swiper)
            this.$emit('slidechange', this.swiper.activeIndex);
    }
}

