import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { value: "" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 1,
  class: "scrollListContainer"
}
const _hoisted_6 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      tabindex: "0",
      "aria-label": _ctx.config.label
    }, _toDisplayString(_ctx.config.label), 9, _hoisted_2),
    (_ctx.config.isSelect)
      ? _withDirectives((_openBlock(), _createElementBlock("select", {
          key: 0,
          class: "form-control",
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue[0]) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitModel(_ctx.localValue)))
          ]
        }, [
          _createElementVNode("option", _hoisted_3, [
            _createVNode(_component_localized_text, {
              localizedKey: "Seleziona una risposta",
              text: "Seleziona una risposta"
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.options, (item, i) => {
            return (_openBlock(), _createElementBlock("option", {
              key: i,
              value: item
            }, _toDisplayString(item), 9, _hoisted_4))
          }), 128))
        ], 512)), [
          [_vModelSelect, _ctx.localValue[0]]
        ])
      : (_ctx.config.isRispostaMultipla)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.options, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "scrollListItem",
                key: i
              }, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  class: "form-check",
                  value: item,
                  "onUpdate:modelValue": [
                    _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue) = $event)),
                    _cache[3] || (_cache[3] = ($event: any) => (_ctx.emitModel(_ctx.localValue)))
                  ]
                }, null, 8, _hoisted_6), [
                  [_vModelCheckbox, _ctx.localValue]
                ]),
                _createTextVNode(" " + _toDisplayString(item), 1)
              ]))
            }), 128))
          ]))
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localValue[0]) = $event)),
              _cache[5] || (_cache[5] = ($event: any) => (_ctx.emitModel(_ctx.localValue)))
            ]
          }, null, 512)), [
            [_vModelText, _ctx.localValue[0]]
          ])
  ]))
}