import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/appuntamenti',
            meta: {
                showNavbar: true
            },
            component: { render: () => h(RouterView) },
            children: [
                { 
                    path: '',
                    name: 'appuntamenti',
                    component: () => import("./appuntamenti.vue")
                },
                { 
                    path: ':identifier',
                    name: 'appuntamentiDetail',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./appuntamentiDetail.vue")
                },
                { 
                    path: 'restricted/:identifier',
                    name: 'appuntamentiRestrictedDetail',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./appuntamentiRestrictedDetail.vue")
                },
                { 
                    path: ':identifier/modifica',
                    name: 'appuntamentiDetailModifica',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./appuntamentiDetailModifica.vue")
                },
                { 
                    path: ':identifier/annullata/:paid?',
                    name: 'appuntamentiDetailAnnullata',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./appuntamentiDetailAnnullata.vue")
                },
                //SUPPORTO
                { 
                    path: 'supporto/:identifier',
                    name: 'appuntamentiSupportoDetail',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./appuntamentiSupportoDetail.vue")
                },
                { 
                    path: 'supporto/:identifier/modifica',
                    name: 'appuntamentiSupportoDetailModifica',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./appuntamentiSupportoDetailModifica.vue")
                },
                { 
                    path: 'supporto/:identifier/annullata',
                    name: 'appuntamentiDetailSupportoAnnullata',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./appuntamentiDetailSupportoAnnullata.vue")
                },
                //RESTRICTED
                { 
                    path: 'restricted/:identifier',
                    name: 'appuntamentiRestrictedDetail',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./appuntamentiRestrictedDetail.vue")
                },
            ]
        },
    ];
};