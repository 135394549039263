
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel'

@Options({})
export default class opDatetime extends mixins(WithModel) {

    onModelValueChange(next, prev){
        let date = new Date(next);
        let years = date.getFullYear().toString();
        while(years.length < 4){
            years = years.padStart(4, "0");
        } 
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;

        this.localValue = years +  "-" + months + "-" + days;
    }

    emitIsoDate(ev){
        try {
            let toEmit = new Date(ev.target.value).toISOString();
            this.emitModel(toEmit);
        } catch(ex){
            this.emitModel(null);
        }
    }

}
