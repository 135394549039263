import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';
import academy from '../academy';
import atupertu from '../atupertu';
import webinar from '../webinar';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/home',
            meta: {
                showNavbar: true
            },
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import("./home.vue")
                },
                ...academy.routes(),
                ...atupertu.routes(),
                ...webinar.routes(),
            ]
        },
    ];
};