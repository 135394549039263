import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/academy',
            meta: {
                showNavbar: false
            },
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '',
                    name: 'academy',
                    component: () => import("./academy.vue")
                },
                {
                    path: ':identifier',
                    name: 'academyDetail',
                    component: () => import("./academyDetail.vue")
                },
            ]
        }
    ];
};