
    import { Options, Vue } from 'vue-class-component';
    import { Prop, Watch } from 'vue-property-decorator';
    import * as OM from '@/Model'
    
    @Options({})
    export default class AlertModal extends Vue {
    
        @Prop() title: string;
        @Prop() text: string;
        @Prop({
            default: 'OK'
        }) okText: string;
        @Prop() okCallback: () => Promise<any>;
    
        name: string = Date.now().toString();
    
        mounted(){
            (this.$refs.closeButton as HTMLButtonElement).focus();
        }
    
        async ok(){
            if(this.okCallback){
                await this.okCallback();
            }
            this.$emit('close');
        }
    
    }
    