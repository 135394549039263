import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02baacc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "swiperContainer",
  ref: "swiperContainer"
}
const _hoisted_2 = {
  key: 0,
  class: "swiper-pagination p-2"
}

export function render(_ctx: any,_cache: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showPagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["swiper-wrapper", _ctx.showPagination ? 'mt-2':''])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 512))
}