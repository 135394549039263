import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import router from '@/router';
import { CompanyClient, LoginClient, UserClient } from './Services';
import store from '@/store';
import { JsonClone } from '@/utils';

class _LoginServices {
    
    loginCallback = (loginResponse: OM.LoginResponse) => {
        return new Promise<void>((resolve, reject) => {
            CommonServices.setAuthToken(loginResponse.token);
            store.state.getNotificheCount();
            StorageServices.setUserIdentifier(loginResponse.userIdentifier);
            UserClient.getById(loginResponse.userIdentifier)
            .then(x => {
                store.state.user = x;
                resolve();
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    logout(){
        store.state.notificheCount = 0;
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            resolve();
        })
        return prom;
    }

}

export let LoginServices = new _LoginServices();