import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/notifiche',
            meta: {
                showNavbar: true
            },
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '',
                    name: 'notifiche',
                    component: () => import("./notifiche.vue")
                }
            ]
        },
    ];
};