
import * as OM from '@/Model';
import { StripeClient } from '@/services/Services'
import StripeSavedCards from './stripeSavedCards.vue';
import { stripeKey } from '@/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        StripeSavedCards
    }
})
export default class StripeForm extends Vue {

    @Prop() userIdentifier: string;
    @Prop() userMail: string;
    @Prop() userName: string;
    @Prop() anonymous: boolean;
    @Prop() intentCb: () => Promise<string>;

    showNewCard: boolean = false;
    paid: boolean = false;
    paying: boolean = false;
    cards: OM.StripeCardDetailVm[] = null;
    
    SiteVersions = OM.SiteVersion;

    selectedCard: string = "";

    saveCard: boolean = false;

    stripe: any;
    card: any;
    cardNumber: any;
    cardExpiry: any;
    cardCvc: any;
    cardholderName: string = "";

    clientSecret: string = "";

    placeholder1: string = "";

    priceList = [
        {
            name: "Singolo",
            price: 81,
            discountPercentage: 10,
            previousPrice: 90
        },
        {
            name: "Singolo",
            price: 81,
            discountPercentage: 10,
            previousPrice: 90
        },
        {
            name: "Singolo",
            price: 81,
            discountPercentage: 10,
            previousPrice: 90
        },
        {
            name: "Singolo",
            price: 81,
            discountPercentage: 10,
            previousPrice: 90
        }
    ]

    get isTestMode(){
        return stripeKey.indexOf('pk_test') > -1
    }

    setShowNewCard(){
        this.showNewCard = true;
        this.selectedCard = "";
        this.$nextTick(() => {
            this.mountStripe();
        });
    }

    created(){
        this.placeholder1 = LocalizationServices.GetLocalizedValue('Nome Cognome', 'Nome Cognome');

        if(!this.anonymous){
            StripeClient.getSavedCards()
            .then(x => {
                this.cards = x;
                if(this.cards.length == 0){
                    this.setShowNewCard();
                }
            })
        } else {
            this.setShowNewCard();
        }
        this.stripe = Stripe(stripeKey);
        this.intentCb()
        .then(x => {
            this.clientSecret = x;
        })
    }

    mountStripe(){
        this.showNewCard = true;
        this.selectedCard = '';
        var elements = this.stripe.elements();
        var style = {
            base: {
                color: "#353A50",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#6c757d",
                },
            },
            invalid: {
                color: "#dc3545",
                iconColor: "#dc3545",
            },
        };
        // this.card = elements.create("card", { hidePostalCode: true, style: style });
        // this.card.mount(this.$refs.cardelement);
        this.cardNumber = elements.create('cardNumber', { style: style });
        this.cardNumber.mount(this.$refs.cardnumber);

        this.cardExpiry = elements.create('cardExpiry', { style: style });
        this.cardExpiry.mount(this.$refs.cardexpiry);
        
        this.cardCvc = elements.create('cardCvc', { style: style });
        this.cardCvc.mount(this.$refs.cardcvc);

        this.cardNumber.addEventListener('change', ({error}) => {
            const displayError = <HTMLElement>this.$refs.carderrors;
            if (error) {
                displayError.textContent = error.message;
            } else {
                displayError.textContent = '';
            }
        });
    }

    get canPay(){
        return !!this.clientSecret && this.cardholderName.trim();
    }

    pay(){
        this.paying = true;
		if(this.selectedCard != "") {
            this.confirmCardPayment(this.selectedCard);
		} else {
            // this.stripe.createToken(this.card)
            this.stripe.createToken(this.cardNumber)
            .then(result => {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = <HTMLElement>this.$refs.carderrors;
                    errorElement.textContent = result.error.message;
                    this.paying = false;
                } else {
                    this.confirmCardPayment({
                        card: {
                            token: result.token.id
                        },
                        billing_details: {
                            name: this.userName,
                        },
                    })
                }
            })
		}
		
    }

    confirmCardPayment(payment_method){
        this.stripe.confirmCardPayment(this.clientSecret, {
            payment_method: payment_method,
        })
        .then((paymentResult) => {
            if(paymentResult.error){
                var errorElement = <HTMLElement>this.$refs.carderrors;
                errorElement.textContent = paymentResult.error.message;
                this.paying = false;
            } else {
                this.paying = false;
                this.$emit('complete', paymentResult.paymentIntent.id);
                if(this.saveCard && !this.isTestMode){
                    this.addCardToCustomer();
                }
            }
        });
    }

    addCardToCustomer(){
		this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
                name: this.cardholderName,
            }
        })
        .then((result) => {
            if (result.error) {
                alert(result.error);
            } else {
                StripeClient.addCardToCustomer({
                    userIdentifier: this.userIdentifier,
                    paymentMethodId: result.paymentMethod.id
                })
            }
        });
    }

}

