import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bigTitle" }
const _hoisted_2 = { class: "mt-1 text-orange" }
const _hoisted_3 = { class: "form-group mt-3" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "d-flex align-items-center mt-3" }
const _hoisted_6 = { class: "ligthGrey" }
const _hoisted_7 = { class: "text-center mt-3" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_10 = { class: "midTitle" }
const _hoisted_11 = { class: "scrollListContainer mt-2" }
const _hoisted_12 = { class: "text-orange" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_15 = { class: "text-default" }
const _hoisted_16 = ["href"]
const _hoisted_17 = {
  key: 1,
  class: "mt-1"
}
const _hoisted_18 = { class: "ligthGrey" }
const _hoisted_19 = {
  key: 1,
  class: "mt-5"
}
const _hoisted_20 = { class: "midTitle" }
const _hoisted_21 = { class: "scrollListContainer" }
const _hoisted_22 = { class: "text-orange" }
const _hoisted_23 = { class: "" }
const _hoisted_24 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_25 = { class: "text-default" }
const _hoisted_26 = ["href"]
const _hoisted_27 = {
  key: 1,
  class: "mt-1"
}
const _hoisted_28 = { class: "ligthGrey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_toggler = _resolveComponent("toggler")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        localizedKey: "Domande e curiosità?",
        text: "Domande e curiosità?"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        localizedKey: "La domanda è finalizzata all’approfondimento dei temi proposti",
        text: "La domanda è finalizzata all’approfondimento dei temi proposti e non \r\n            sostituisce in alcun modo una consulenza individuale. Gli eventuali consigli contenuti nelle risposte hanno \r\n            valenza generale e non rappresentano una prescrizione individualizzata."
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("textarea", {
        tabindex: "0",
        class: "form-control ligthGrey",
        placeholder: _ctx.placeholder1,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.questionText) = $event)),
        "aria-label": "area di testo per domande relative"
      }, "\r\n            ", 8, _hoisted_4), [
        [_vModelText, _ctx.questionText]
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_toggler, {
          tabindex: "0",
          modelValue: _ctx.publicQuestion,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.publicQuestion) = $event)),
          class: "me-2"
        }, null, 8, ["modelValue"]),
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_localized_text, {
            localizedKey: "Rendi la domanda visibile a tutti (il tuo nome non sarà visibile)",
            text: "Rendi la domanda visibile a tutti (il tuo nome non sarà visibile)"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          tabindex: "0",
          role: "button",
          class: "btn btn-primary",
          disabled: !_ctx.questionText.trim(),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveQuestion && _ctx.saveQuestion(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Invia Domanda",
            text: "Invia Domanda"
          })
        ], 8, _hoisted_8)
      ])
    ]),
    (_ctx.domandeUtente.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_localized_text, {
              localizedKey: "Le tue domande",
              text: "Le tue domande"
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.domandeUtente, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "listItem mb-3",
                key: item.identifier
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$filters.date(item.createdOn, 'DD/MM/YYYY HH:mm')), 1),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(item.question), 1)
                ]),
                (item.answer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Risposta:",
                          text: "Risposta:"
                        })
                      ]),
                      _createTextVNode(" " + _toDisplayString(item.answer.text) + " ", 1),
                      _createElementVNode("div", null, [
                        (item.answer.attachment)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              role: "button",
                              class: "text-orange",
                              href: item.answer.attachment.publicUrl,
                              target: "_blank"
                            }, [
                              _createVNode(_component_localized_text, {
                                localizedKey: "Apri allegato",
                                text: "Apri allegato"
                              })
                            ], 8, _hoisted_16))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Risposta non ancora ricevuta",
                          text: "Risposta non ancora ricevuta"
                        })
                      ])
                    ]))
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.domandeAltri.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_localized_text, {
              localizedKey: "Domande degli altri utenti",
              text: "Domande degli altri utenti"
            })
          ]),
          _createElementVNode("div", _hoisted_21, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.domandeAltri, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "listItem mb-3",
                key: item.identifier
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$filters.date(item.createdOn, 'DD/MM/YYYY HH:mm')), 1),
                  _createElementVNode("div", _hoisted_23, _toDisplayString(item.question), 1)
                ]),
                (item.answer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Risposta:",
                          text: "Risposta:"
                        })
                      ]),
                      _createTextVNode(" " + _toDisplayString(item.answer.text) + " ", 1),
                      _createElementVNode("div", null, [
                        (item.answer.attachment)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              role: "button",
                              class: "text-orange",
                              href: item.answer.attachment.publicUrl,
                              target: "_blank"
                            }, [
                              _createVNode(_component_localized_text, {
                                localizedKey: "Apri allegato",
                                text: "Apri allegato"
                              })
                            ], 8, _hoisted_26))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Risposta non ancora ricevuta",
                          text: "Risposta non ancora ricevuta"
                        })
                      ])
                    ]))
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}