import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';
import academy from '../academy';
import atupertu from '../atupertu';
import webinar from '../webinar';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/imieipacchetti',
            meta: {
                showNavbar: true
            },
            component: () => import("./imieipacchetti.vue")
        },
    ];
};