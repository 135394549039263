import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = { role: "document" }
const _hoisted_3 = {
  key: 0,
  class: "header grey-access"
}
const _hoisted_4 = ["innerHTML", "id"]
const _hoisted_5 = { class: "body" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mt-4 mw-700" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "opModal alertModal",
    role: "dialog",
    "aria-labelledby": _ctx.name + 'dialog_title'
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", {
              innerHTML: _ctx.title,
              id: _ctx.name + 'dialog_title'
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", {
          class: "grey-access",
          innerHTML: _ctx.text,
          id: "__dialog_text"
        }, null, 8, _hoisted_6),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            role: "button",
            "aria-label": "Chiudi messaggio",
            type: "button",
            ref: "closeButton",
            class: "btn btn-block btn-primary noShadow",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ok && _ctx.ok(...args))),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.ok && _ctx.ok(...args)), ["enter"]))
          }, [
            _createElementVNode("span", { innerHTML: _ctx.okText }, null, 8, _hoisted_8)
          ], 544)
        ])
      ])
    ])
  ], 8, _hoisted_1))
}