
import store from '@/store';
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Calendar extends Vue {

    @Prop() date: number;

    mode: string = "";

    originalWeekDays: string[] = [];
    weekDays: string[] = [];
    hours: string[] = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", 
        "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00"];
    days: CalendarDay[] = [];

    currentDate: Date = new Date();
    currentViewStartDate: Date = new Date();
    currentViewEndDate: Date = new Date();

    daysContainer: HTMLDivElement;

    created(){
        if(store.state.formatLocaleDate == 0){
            this.originalWeekDays = ["L", "M", "M", "G", "V", "S", "D"];
        }else if(store.state.formatLocaleDate == 1){
            this.originalWeekDays = ["M", "T", "W", "T", "F", "S", "S"];
        }else if(store.state.formatLocaleDate == 2){
            this.originalWeekDays = ["L", "M", "M", "J", "V", "S", "D"];
        }else if(store.state.formatLocaleDate == 3){
            this.originalWeekDays = ["M", "D", "M", "D", "F", "S", "S"];
        }
        
        if(!this.date){
            this.currentDate = new Date(moment.now());
        } else {
            this.currentDate = new Date(this.date);
        }

        this.getDays();
    }

    mounted(){
        this.daysContainer = <HTMLDivElement>this.$refs.daysContainer;
    }

    getDays(){
        this.days = this.getCalendarDaysMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
    }

    getCalendarDaysWeek(dayOfTheWeek){
        let currentDay = dayOfTheWeek.getDay();
        if(currentDay == 0){ //domenica 👍
            this.currentViewStartDate = new Date(dayOfTheWeek.getFullYear(), dayOfTheWeek.getMonth(), dayOfTheWeek.getDate() - 6);
            this.currentViewEndDate = new Date(this.currentViewStartDate.getFullYear(), this.currentViewStartDate.getMonth(), this.currentViewStartDate.getDate() + 6);
        } else {
            let ggDiff = currentDay - 1;
            this.currentViewStartDate = new Date(dayOfTheWeek.getFullYear(), dayOfTheWeek.getMonth(), dayOfTheWeek.getDate() - ggDiff)
            this.currentViewEndDate = new Date(this.currentViewStartDate.getFullYear(), this.currentViewStartDate.getMonth(), this.currentViewStartDate.getDate() + 6);
        }

        let ris: CalendarDay[] = [];
        let currentDate = new Date(this.currentViewStartDate);
        this.weekDays = [];
        for(let i = 0; i < 7; i++){
            this.weekDays[i] = this.originalWeekDays[i] + " " + currentDate.getDate();
            let classes = [];
            if(isToday(currentDate)){ //currentDate.getDay() == dayOfTheWeek.getDay()){
                classes.push('currentDay');
            }
            let pushHours = this.hours.map(x => {
                let hourClasses = [];
                if(isToday(currentDate)){
                    if(currentDate.getDay() == dayOfTheWeek.getDay()){
                        if(parseInt(x.split(":")[0]).toString() == dayOfTheWeek.getHours().toString()){
                            hourClasses.push('currentHour');
                        }
                    }
                }
                return {
                    hour: x,
                    classes: hourClasses
                }
            });
            let toPush = {
                date: new Date(currentDate.getTime()),
                classes: classes,
                hours: pushHours
            }
            ris.push(toPush);
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
        }
        return ris;
    }


    getCalendarDaysMonth(month: number, year: number){
        let currentMonthStartDate = new Date(year, month, 1, 0, 0, 0);
        let currentMonthEndDate = new Date(year, month + 1, 0, 0, 0, 0);

        let dayOfWeekStartDate = currentMonthStartDate.getDay();
        //lunedì=1, domenica=0
        //normalizzo domenica a 1
        let previousDaysToGet;
        if(dayOfWeekStartDate == 0)
            previousDaysToGet = 6;
        else
            previousDaysToGet = dayOfWeekStartDate - 1;

        let prevMonthEndDate = new Date(year, month, 0, 0, 0, 0);
        this.currentViewStartDate = new Date(currentMonthStartDate.setDate(currentMonthStartDate.getDate() - previousDaysToGet));
        let totalDays = 42;//(endDate.getTime() - startDate.getTime()) / (24*3600*1000);
        let ris: CalendarDay[] = [];
        let currentDate = new Date(this.currentViewStartDate);
        for(let i = 0; i < totalDays; i++){
            let classes = [];
            if(currentDate.getMonth() < this.currentDate.getMonth())
                classes.push('prevMonth');
            if(currentDate.getMonth() > this.currentDate.getMonth())
                classes.push('nextMonth');
            if(isToday(currentDate)){
                classes.push('currentDay');
            }
            ris.push({
                date: new Date(currentDate.getTime()),
                classes: classes,
                hours: []
            });
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
        }
        this.currentViewEndDate = new Date(currentDate);
        return ris;
    }

}

function isToday(date: Date){
    var now = new Date(Date.now());
    if(date.getDate() == now.getDate() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()){
        return true;
    }
}

class CalendarDay {
    date: Date;
    classes: string[];
    hours: CalendarHour[];
}

class CalendarHour {
    hour: string;
    classes: string[];
}

