
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { Options, Vue } from 'vue-class-component';
import { FreeQuestionClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class domandeSection extends Vue {

    @Prop() contentIdentifier: string;
    @Prop() category: string;
    @Prop() sectionType: string;

    questionText: string = "";
    publicQuestion: boolean = false;
    domandeUtente: OM.FreeQuestion[] = [];
    domandeAltri: OM.FreeQuestion[] = [];
    placeholder1: string = "";

    created(){
        this.placeholder1 =  LocalizationServices.GetLocalizedValue('Scrivi qui la tua domanda [...]','Scrivi qui la tua domanda [...]');
        this.init();
    }

    init(){
        FreeQuestionClient.getByCompanyAndContent(this.contentIdentifier, store.state.appCode)
        .then(x => {
            this.domandeUtente = x.filter(x => x.author.identifier == store.state.user.identifier);
            this.domandeAltri = x.filter(x => x.author.identifier != store.state.user.identifier);
        })
    }

    saveQuestion(){
        let author = new OM.TextIdentifier2();
        author.name = store.state.user.completeName;
        author.identifier = store.state.user.identifier;
        let question = new OM.FreeQuestion();
        question.contentIdentifier = this.contentIdentifier;
        question.author = author;
        question.companyCode = store.state.appCode;
        question.question = this.questionText;
        question.category = this.category;
        question.answer = null;
        question.sectionType = this.sectionType;
        question.isPublic = this.publicQuestion;

        FreeQuestionClient.save(question)
        .then(x => {
            this.init();
            this.questionText = "";
            this.publicQuestion = false;
            var title = LocalizationServices.GetLocalizedValue("Domanda inviata!","Domanda inviata!");
            var text = LocalizationServices.GetLocalizedValue("Riceverai una risposta dai nostri professionisti entro 48 ore","Riceverai una risposta dai nostri professionisti entro 48 ore");
            ModalServices.alertModal(title, text);
        });
    }

}
