import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/restricted/:categoria',
            name: 'restricted categoria',
            meta: {
                showNavbar: false
            },
            component: () => import("./restrictedCategoria.vue")
        },
        {
            path: '/restricted/:categoria/:identifier',
            name: 'restrictedCategoriaDetail',
            meta: {
                showNavbar: false
            },
            component: () => import("./restrictedCategoriaDetail.vue")
        },
    ];
};