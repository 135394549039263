import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/atupertu',
            name: 'atupertu',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertu.vue")
        },
        {
            path: '/atupertu/:categoria',
            name: 'atupertuCategoria',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuCategoria.vue")
        },
        {
            path: '/atupertu/:categoria/:identifier',
            name: 'atupertuCategoriaDetail',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuDetail.vue")
        },
        {
            path: '/atupertu/:categoria/:identifier/calendar',
            name: 'atupertuCategoriaDetailCalendar',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuCalendar.vue")
        },
        {
            path: '/atupertu/:identifier/calendar/modify/:prenotazioneIdentifier',
            name: 'atupertuCategoriaDetailCalendarModify',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuCalendar.vue")
        },
        {
            path: '/atupertu/:categoria/:identifier/riepilogo',
            name: 'atupertuCategoriaDetailRiepilogo',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuRiepilogo.vue")
        },
        {
            path: '/atupertu/:categoria/:identifier/payment',
            name: 'atupertuCategoriaDetailPayment',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuPayment.vue")
        },
        {
            path: '/atupertu/:categoria/:identifier/confermata/:isPaid?',
            name: 'atupertuCategoriaDetailConfermata',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuConfermata.vue")
        },
        {
            path: '/atupertu/:categoria/:identifier/purchase',
            name: 'atupertuPurchase',
            component: () => import("./atupertuPurchase.vue")
        },
        //SUPPORTO
        {
            path: '/atupertucalendarsupport',
            name: 'atupertucalendarsupport',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuCalendarSupport.vue")
        },
        {
            path: '/atuperturiepilogosupport',
            name: 'atuperturiepilogosupport',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuRiepilogoSupport.vue")
        },
        {
            path: '/atupertuconfirmsupporto',
            name: 'atupertuconfirmsupporto',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuConfirmSupporto.vue")
        },
        {
            path: '/atupertuconfermatasupport',
            name: 'atupertuconfermatasupport',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuConfermataSupporto.vue")
        },
        {
            path: '/atupertucalendarsupport/modify/:prenotazioneIdentifier',
            name: 'atupertucalendarsupportmodify',
            meta: {
                showNavbar: false
            },
            component: () => import("./atupertuCalendarSupport.vue")
        },
    ];
};