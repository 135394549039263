
import * as OM from '@/Model';
import * as Const from '@/const';
import * as VM from '@/viewModel';
import { Options, Vue } from 'vue-class-component';
import { PrenotazioneClient } from '@/services/Services';
import { PrenotazioneServices } from '@/services/PrenotazioneServices';
import { Prop } from 'vue-property-decorator';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class comments extends Vue {

    @Prop() comments: OM.Comment[];
    @Prop() readonly: boolean;
    
    fileForComment: File = null;
    commentText: string = "";
    notifyUser: boolean = false;
    placeholder1: string = "";

    created(){
       this.placeholder1 =  LocalizationServices.GetLocalizedValue('Aggiungi un commento','Aggiungi un commento');
    }

    addComment(){
        if(!this.canSaveComment)
            return;

        this.$emit('add', {
            file: this.fileForComment,
            text: linkifyHtml(this.commentText),
            notify: this.notifyUser
        });
        this.fileForComment = null;
        this.commentText = "";
    }
    
    get canSaveComment(){
        return this.commentText || this.fileForComment;
    }

}
