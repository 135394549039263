
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class FileInput extends mixins(WithModel) {

    onFileSelect(ev){
        let file = ev.target.files[0];
        this.emitModel(file);
    }
}

