import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/webinar',
            meta: {
                showNavbar: false
            },
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '',
                    name: 'webinar',
                    meta: {
                        showNavbar: true
                    },
                    component: () => import("./webinar.vue")
                },
                {
                    path: ':identifier',
                    name: 'webinarDetail',
                    meta: {
                        showNavbar: true
                    },
                    component: () => import("./webinarDetail.vue")
                },
            ]
        }
    ];
};