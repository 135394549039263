import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "squaredCalendar" }
const _hoisted_2 = { class: "weekDaysContainer" }
const _hoisted_3 = {
  class: "calendarDaysContainer",
  ref: "daysContainer"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.originalWeekDays, (giorno, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "weekDay",
          key: i
        }, [
          _createElementVNode("b", {
            class: _normalizeClass({ 'text-orange': i > 4 })
          }, _toDisplayString(giorno), 3)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", { days: _ctx.days }, () => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["calendarDay", day.classes]),
            key: i,
            onClick: ($event: any) => (_ctx.$emit('dateClick', day.date))
          }, _toDisplayString(_ctx.$filters.date(day.date, "DD")), 11, _hoisted_4))
        }), 128))
      ])
    ], 512)
  ]))
}