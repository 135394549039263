import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/profilo',
            meta: {
                showNavbar: true
            },
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '',
                    name: 'profilo',
                    meta: {
                        showNavbar: true
                    },
                    component: () => import("./profilo.vue")
                },
                {
                    path: 'edit',
                    name: 'profiloedit',
                    meta: {
                        showNavbar: false
                    },
                    component: () => import("./profiloEdit.vue")
                },
                {
                    path: 'transactions',
                    name: 'transactions',
                    meta: {
                        showNavbar: true
                    },
                    component: () => import("./transactions.vue")
                },
            ]
        },
    ];
};